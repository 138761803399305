import * as React from 'react';
import { Link } from 'gatsby';
import Seo from '../components/seo';
import PageDefaultLayout from '../layouts/page-default';

// markup
const NotFoundPage = () => {
  return (
    <PageDefaultLayout>
      <Seo title={'404'}></Seo>
      <h1>Page not found</h1>
      <p>
        Sorry... The requested page does not exist.
        <br />
        <Link to="/">Go home</Link>.
      </p>
    </PageDefaultLayout>
  );
};

export default NotFoundPage;
